<template>
  <div class="container">
    <div class="row my-5">
      <div class="col-sm search-column">
        <div class="column-inner">
          <!-- Search controls -->
          <div class="search-controls">
            <h2 class="my-3">Find a Recipe</h2>
            <form form @submit.prevent="getData" class="form-inline my-4">
              <div class="form-group">
                <input type="text" id="keyword-search-control" class="form-control mr-3" v-model="searchQuery" placeholder="Search..." required>
              </div>
              <button class="btn btn-primary" type="submit">Go</button>
            </form>
            <p class="mt-4">{{ recipes.length }} results</p>
          </div>
          <!-- Search results -->
          <div class="search-results">
            <!-- result cads -->
            <div class="card mb-2" v-for="recipe in recipes" v-bind:key="recipe.id">
              <div class="card-body">
                <h4 class="card-title">{{ recipe.Title }}</h4>
                <p class="card-text"> {{ recipe.Ingredients }}</p>
              </div>
            </div>
            <!-- End result cards -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainPage',
  props: {
    msg: String
  },
  data () {
    return {
      searchQuery: '',
      recipes: []
    }
  },
  methods: {
    async getData () {
      try {
        const response = await fetch('https://nuor29xk0g.execute-api.us-west-1.amazonaws.com/cook-fair-trade/items?query=' + this.searchQuery)
        const respJson = await response.json()
        this.recipes = respJson
        //console.log(this.recipes);
      } catch (error) {
        console.log(error)
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.search-column {
  padding: 1em;
}

.column-inner {
  border: 1px solid #eee;
  padding: 2em;
  max-width: 40em;
  margin: 0 auto;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
}

.search-results, .search-controls {
  text-align: left;
}

</style>
